// Support component names relative to this directory:
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import axios from 'axios';

const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

axios.interceptors.request.use((config) => {
  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  config.headers = config.headers || {};
  config.headers['X-CSRF-TOKEN'] = csrfToken;
  config.headers.ACCEPT = 'application/json';
  config.headers['CONTENT-TYPE'] = 'application/json';

  return config;
});
